import React, { useRef, useEffect } from "react"
import { withPrefix } from "gatsby"
import lottie from "lottie-web"

import StyledSearchContainer from "./searchSvgAnimated.style"

const SearchSvgAnimated = ({ showOver = false, theme = "light" }) => {
  const elementClass = "c-search-animation"
  const path = `${withPrefix("/")}assets/animations/`
  const searchLottie = useRef(null)
  const searchContainer = useRef(null)

  // Lotties
  const addLotties = () => {
    searchLottie.current = lottie.loadAnimation({
      container: searchContainer.current,
      renderer: "svg",
      loop: false,
      autoplay: false,
      path: `${path}search_over/data.json`,
    })
  }

  useEffect(() => {
    addLotties()

    return () => {
      if (searchLottie.current) {
        searchLottie.current.stop()
        searchLottie.current.destroy()
        searchLottie.current = null
      }
    }
  }, [])

  useEffect(() => {
    if (searchLottie.current) {
      if (showOver) searchLottie.current.play()
      else searchLottie.current.stop()
    }
  }, [showOver])

  return (
    <StyledSearchContainer className={`${elementClass}`} theme={theme}>
      <div className={`${elementClass}__animation`} ref={searchContainer} />
    </StyledSearchContainer>
  )
}

export default SearchSvgAnimated
