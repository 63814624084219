import React from "react"
import { string } from "prop-types"

import StyledNavigation from "./Navigation.style"

const Navigation = ({ children, className }) => {
  return <StyledNavigation className={className}>{children}</StyledNavigation>
}

Navigation.propTypes = {
  className: string,
}

Navigation.defaultProps = {
  className: "",
}

export default Navigation
