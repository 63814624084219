import styled from "styled-components"
import { device } from "../../../theme/mediaQueries"

const StyledInputContainer = styled.div`
  position: relative;
  width: 100%;
  border-radius: 15px;
  border: 1px solid rgb(0, 0, 0, 0.2);
  height: 52px;
  overflow: hidden;
  padding: 0 20px;
  background-color: ${props => props.theme.vars.white};

  input {
    font-family: ${props => props.theme.vars.openSans};
    font-weight: ${props => props.theme.vars.fontRegular};
    font-size: 16px;
    border-width: 0;
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    outline: none;
  }
  .e-input-search__svg-search {
    cursor: pointer;
    height: 46px;
    position: absolute;
    right: -15px;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 46px;
  }

  @media ${device.sm} {
    height: 54px;
    padding: 0 27px;

    .e-input-search__svg-search {
      right: -10px;
    }
  }
`

export default StyledInputContainer
