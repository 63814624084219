import * as React from "react"

function SvgSocialInstagramDark({ width = "50", height = "50", className = "", color = "#222221" }) {
  return (
    <svg width={width} height={height} className={className} viewBox="0 0 40 40" aria-label="title" fill="currentColor">
      <title>Instagram</title>
      <path d="M32.5 10.6c-.3-.7-.7-1.3-1.2-1.8s-1.1-.9-1.8-1.2c-.6-.3-1.5-.6-3-.6-1.7-.1-2.2-.1-6.5-.1s-4.8 0-6.5.1c-1.6.1-2.4.3-3 .6-.6.2-1.3.6-1.8 1.1-.5.5-.9 1.1-1.2 1.8-.2.6-.5 1.5-.5 3-.1 1.7-.1 2.2-.1 6.5s0 4.8.1 6.5c.1 1.6.3 2.4.6 3 .3.7.7 1.3 1.2 1.8s1.1.9 1.8 1.2c.6.2 1.4.5 3 .6 1.7.1 2.2.1 6.5.1s4.8 0 6.5-.1c1.6-.1 2.4-.3 3-.6 1.4-.5 2.5-1.6 3-3 .2-.6.5-1.4.6-3 .1-1.7.1-2.2.1-6.5s0-4.8-.1-6.5c-.2-1.5-.5-2.4-.7-2.9zM20 28.2c-4.5 0-8.2-3.7-8.2-8.2s3.7-8.2 8.2-8.2c4.5 0 8.2 3.7 8.2 8.2s-3.7 8.2-8.2 8.2zm8.5-14.8c-1.1 0-1.9-.9-1.9-1.9s.9-1.9 1.9-1.9c1.1 0 1.9.9 1.9 1.9.1 1-.8 1.9-1.9 1.9z" fill="none" />
      <path d="M20 11.8c-4.5 0-8.2 3.7-8.2 8.2s3.7 8.2 8.2 8.2c4.5 0 8.2-3.7 8.2-8.2s-3.7-8.2-8.2-8.2zm0 13.5c-2.9 0-5.3-2.4-5.3-5.3s2.4-5.3 5.3-5.3 5.3 2.4 5.3 5.3-2.4 5.3-5.3 5.3z" fill={color} />
      <circle cx={28.5} cy={11.5} r={1.9} fill={color} />
      <path d="M35.9 13.4c-.1-1.7-.3-2.9-.7-3.9-.4-1.1-1-2-1.8-2.8-.8-.8-1.8-1.4-2.8-1.8-1-.4-2.2-.7-3.9-.7C24.9 4 24.3 4 20 4s-4.9 0-6.6.1c-1.7.1-2.9.3-3.9.7-1.1.4-2 1-2.8 1.8-.8.9-1.5 1.9-1.9 2.9-.4 1-.7 2.2-.7 3.9C4 15.1 4 15.7 4 20s0 4.9.1 6.6c.1 1.7.3 2.9.7 3.9.4 1.1 1 2 1.8 2.8.8.8 1.8 1.4 2.8 1.8 1 .4 2.2.7 3.9.7 1.8.2 2.4.2 6.7.2 4.3 0 4.9 0 6.6-.1 1.7-.1 2.9-.3 3.9-.7 2.1-.8 3.8-2.5 4.7-4.7.4-1 .7-2.2.7-3.9.1-1.7.1-2.3.1-6.6s0-4.9-.1-6.6zM33 26.5c-.1 1.6-.3 2.4-.6 3-.5 1.4-1.6 2.5-3 3-.6.2-1.4.5-3 .6-1.7.1-2.2.1-6.5.1s-4.8 0-6.5-.1c-1.6-.1-2.4-.3-3-.6-.7-.3-1.3-.7-1.8-1.2s-.9-1.1-1.2-1.8c-.1-.6-.4-1.5-.4-3-.1-1.7-.1-2.2-.1-6.5s0-4.8.1-6.5c.1-1.6.3-2.4.6-3 .3-.7.7-1.3 1.2-1.8s1.1-.9 1.8-1.2c.5-.2 1.4-.5 2.9-.5 1.7-.1 2.2-.1 6.5-.1s4.8 0 6.5.1c1.6.1 2.4.3 3 .6.7.3 1.3.7 1.8 1.2s.9 1.1 1.2 1.8c.2.6.5 1.4.6 3 .1 1.7.1 2.2.1 6.5-.1 4.2-.1 4.7-.2 6.4z" fill={color} />
    </svg>
  )
}

export default SvgSocialInstagramDark
