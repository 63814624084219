import React, { useState, useContext, useEffect } from "react"
import { Link } from "gatsby"
import { string, oneOf, bool, func } from "prop-types"

import { isInternal } from "../../../../helpers/url"
import LayoutContext from "../../../layout/layout.context"
import StyledNavItem from "./NavItem.style"

// To-do add icon

const NavItem = ({ children, iconPosition, className, xsHidden, url, onClick, dkHidden, id, onMouseEnter = null, onMouseLeave = null }) => {
  const classNavItem = "m-nav-item"
  const classNavItemActive = `${classNavItem}__active`
  const [isActive, setIsActive] = useState(false)
  const layoutContext = useContext(LayoutContext)

  useEffect(() => {
    if (id) {
      if (layoutContext.active === id) setIsActive(true)
      else setIsActive(false)
    }
  }, [layoutContext.active])

  if (!url) {
    return (
      <StyledNavItem xsHidden={xsHidden} dkHidden={dkHidden} className={`${className} ${classNavItem}__${iconPosition} ${isActive ? classNavItemActive : ""}`} onClick={onClick} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        {children}
      </StyledNavItem>
    )
  }
  if (isInternal(url)) {
    return (
      <Link to={url}>
        <StyledNavItem xsHidden={xsHidden} dkHidden={dkHidden} className={`${className} ${classNavItem}__${iconPosition}`}>
          {children}
        </StyledNavItem>
      </Link>
    )
  }
  return (
    <StyledNavItem xsHidden={xsHidden} dkHidden={dkHidden} className={`${className} ${classNavItem}__${iconPosition}`}>
      <a href={url} target="_blank" rel="noopener noreferrer">
        {children}
      </a>
    </StyledNavItem>
  )
}

NavItem.propTypes = {
  iconPosition: oneOf(["right", "left"]),
  xsHidden: bool,
  url: string,
  onClick: func,
}

NavItem.defaultProps = {
  iconPosition: "left",
  xsHidden: false,
  url: null,
  onClick: () => {},
}

export default NavItem
