import styled, { css } from "styled-components"
import { device } from "../../../../theme/mediaQueries"

export const StyledNavBar = styled.div`
  color: ${props => props.theme.vars.white};
  transition: color 200ms ease;
  width: 100%;

  .m-navbar__categories {
    display: none;
  }
  .m-navbar__categories {
    justify-content: center;
    width: 700px;
    text-transform: uppercase;
    width: 584px;

    .m-navbar__categories-item {
      opacity: 0.4;
    }
  }
  .m-navbar__hamburguer {
    align-items: center;
    display: flex;

    svg {
      height: 12px;
      width: 24px;
    }
  }
  .m-navbar__hamburguer-close,
  .m-navbar__search-close {
    display: none;

    svg {
      height: 24px;
      width: 24px;
    }
  }
  .m-navbar__search {
    height: 20px;
    position: relative;
    width: 20px;

    .c-search-animation {
      height: 100%;
      width: 100%;
    }
    .c-search-animation__animation {
      position: absolute;
      left: 45%;
      top: 45%;
      transform: translate(-50%, -50%);
      svg path {
        fill: ${props => props.theme.vars.white};
      }
    }
  }
  .m-navbar__jeep-logo {
    svg {
      height: 13px;
      width: 105px;
    }
  }

  ${props =>
    props.formOption &&
    css`
      color: ${props.theme.vars.black};
      .c-search-animation__animation {
        svg path {
          fill: ${props.theme.vars.black};
        }
      }
    `}
  ${props =>
    props.isHome &&
    css`
      .m-navbar__logo-mb {
        display: flex;
      }
    `}
  ${props =>
    props.solid &&
    css`
      .m-navbar__logo-mb {
        display: flex;
      }
    `}
  ${props =>
    props.activeHamburger &&
    css`
      .m-navbar__logo-mb {
        display: flex;
      }
      .m-navbar__hamburguer-close {
        display: block;
      }
      .m-navbar__hamburguer {
        display: none;
      }
    `}

  ${props =>
    props.activeSearch &&
    css`
      .m-navbar__logo-mb {
        display: flex;
      }
      .m-navbar__search-close {
        display: block;
      }
      .m-navbar__search {
        display: none;
      }
    `}

  @media ${device.md} {
    .m-navbar__categories {
      justify-content: space-between;
      font-size: 12px;
      font-family: ${props => props.theme.vars.avenirNext};
      font-weight: ${props => props.theme.vars.fontBold};
      display: flex;
    }
    .m-navbar__logo-mb {
      display: flex;
    }
    .m-navbar__hamburguer-mb {
      display: none;
    }

    ${props =>
      props.isHome &&
      css`
        .m-navbar__categories {
          display: none;
        }
      `}
    ${props =>
      props.activeSearch &&
      css`
        .m-navbar__categories {
          display: flex;
        }
      `}
    ${props =>
      props.solid &&
      css`
        .m-navbar__categories {
          display: flex;
        }
      `}
  }

  @media ${device.lg} {
    .m-navbar__categories {
      width: 696px;
    }
  }

  @media ${device.xl} {
    .m-navbar__categories {
      width: 708px;
    }
  }
`

export const ItemsWrapper = styled.div`
  align-items: center;
  display: flex;

  .m-navbar-__hamburguer {
    height: 24px;
    width: 24px;
  }
  .m-navbar-__search {
    width: 20px;
  }

  @media ${device.md} {
    &:hover {
      opacity: 0.8;
    }
    &.m-navbar__categories {
      height: 100px;
      padding-top: 6px;

      .m-navbar__categories-item {
        height: 58%;
        align-items: center;
        display: flex;
      }

      ${props =>
        props.activeSearch &&
        css`
          height: 100%;
          align-self: flex-end;
          .m-navbar__categories-item {
            height: unset;
          }
        `}
      ${props =>
        props.solid &&
        css`
          .m-navbar__categories-item {
            height: 100%;
            display: flex;
          }
        `}
    }
    &.m-navbar__categories:hover {
      opacity: 1;
    }
    .m-navbar__categories-item:hover {
      opacity: 1;
    }
    .m-navbar__categories-item.m-nav-item__active {
      opacity: 1;
      border-bottom: 2px solid ${props => props.theme.vars.white};
      ${props =>
        (props.solid || props.activeSearch) &&
        css`
          border-bottom: 2px solid ${props.theme.vars.black};
        `}
    }
  }
`
