import styled from "styled-components"

const StyledSearchContainer = styled.div`
  position: relative;

  .c-search-animation__animation {
    height: 46px;
    pointer-events: none;
    position: relative;
    width: 46px;
  }
`

export default StyledSearchContainer
