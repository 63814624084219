import styled from "styled-components"
import { device } from "../../../theme/mediaQueries"

const FooterContainer = styled.div`
  height: 195px;
  width: 100%;
  background-color: ${props => props.theme.vars.mirage};
  padding-top: 30px;
  margin-top: -1px;
  position: relative;
  z-index: 10;

  @media ${device.sm} {
    height: 145px;
  }

  @media ${device.md} {
    height: 80px;
    padding: 0;
  }

  @media ${device.xl} {
    height: 111px;
  }
`

export default FooterContainer
