// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-audio-jsx": () => import("./../../../src/pages/audio.jsx" /* webpackChunkName: "component---src-pages-audio-jsx" */),
  "component---src-pages-aventura-jsx": () => import("./../../../src/pages/aventura.jsx" /* webpackChunkName: "component---src-pages-aventura-jsx" */),
  "component---src-pages-aviso-de-privacidad-jsx": () => import("./../../../src/pages/aviso-de-privacidad.jsx" /* webpackChunkName: "component---src-pages-aviso-de-privacidad-jsx" */),
  "component---src-pages-busqueda-jsx": () => import("./../../../src/pages/busqueda.jsx" /* webpackChunkName: "component---src-pages-busqueda-jsx" */),
  "component---src-pages-destacadas-jsx": () => import("./../../../src/pages/destacadas.jsx" /* webpackChunkName: "component---src-pages-destacadas-jsx" */),
  "component---src-pages-familia-jsx": () => import("./../../../src/pages/familia.jsx" /* webpackChunkName: "component---src-pages-familia-jsx" */),
  "component---src-pages-historias-jsx": () => import("./../../../src/pages/historias.jsx" /* webpackChunkName: "component---src-pages-historias-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-legales-corporativos-jsx": () => import("./../../../src/pages/legales-corporativos.jsx" /* webpackChunkName: "component---src-pages-legales-corporativos-jsx" */),
  "component---src-pages-off-road-jsx": () => import("./../../../src/pages/off-road.jsx" /* webpackChunkName: "component---src-pages-off-road-jsx" */),
  "component---src-pages-sube-tu-historia-enviado-jsx": () => import("./../../../src/pages/sube-tu-historia/enviado.jsx" /* webpackChunkName: "component---src-pages-sube-tu-historia-enviado-jsx" */),
  "component---src-pages-sube-tu-historia-jsx": () => import("./../../../src/pages/sube-tu-historia.jsx" /* webpackChunkName: "component---src-pages-sube-tu-historia-jsx" */)
}

