import React from "react"
import { SocialFooterContainer, SocialList, LinksList, ItemLink } from "./SocialFooter.style"
import ButtonSocialRoll from "../../elements/buttonSocialRoll/ButtonSocialRoll"

const SocialFooter = () => {
  const classModule = "social-footer"
  return (
    <SocialFooterContainer>
      <SocialList>
        <ButtonSocialRoll className={`${classModule}__btn tag-footer-facebook`} type="facebook_dark" typeRoll="facebook" url="https://www.facebook.com/JeepMexico" />
        <ButtonSocialRoll className={`${classModule}__btn tag-footer-twitter`} type="twitter_dark" typeRoll="twitter" url="https://twitter.com/jeepmx" />
        <ButtonSocialRoll className={`${classModule}__btn tag-footer-instagram`} type="instagram_dark" typeRoll="instagram" url="https://www.instagram.com/jeepmexico" />
      </SocialList>
      <LinksList>
        <ItemLink to="/aviso-de-privacidad/" className={`${classModule}__separator tag-footer-aviso-privacidad`}>
          aviso de privacidad
        </ItemLink>
        <ItemLink to="/legales-corporativos/" className="tag-footer-legales">
          legales
        </ItemLink>
      </LinksList>
    </SocialFooterContainer>
  )
}

export default SocialFooter
