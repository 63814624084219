import { useState, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { getImage, getSrc } from 'gatsby-plugin-image';

const useAllImages = () => {
  const [allImages, setAllImages] = useState(null);

  const query = useStaticQuery(graphql`
    {
      allImageSharp {
        nodes {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
    }
  `);

  useEffect(() => {
    setAllImages(query.allImageSharp.nodes);
  }, []);

  const getTheImage = (baseSrc) => {
    if (!allImages) return null;
    const imgNode = allImages.find((element) => {
      // Match string after final slash
      return (
        element.gatsbyImageData.images.fallback.src.split('/').pop() === baseSrc
      );
    });
    if (!imgNode) return null;
    const image = getImage(imgNode.gatsbyImageData);
    // console.log(image);
    return image;
  };

  const getImageSrc = (baseSrc) => {
    if (!allImages) return null;
    const imgNode = allImages.find((element) => {
      // Match string after final slash
      return (
        element.gatsbyImageData.images.fallback.src.split('/').pop() === baseSrc
      );
    });
    if (!imgNode) return null;
    const src = getSrc(imgNode.gatsbyImageData);
    return src;
  };

  return {
    allImages,
    getTheImage,
    getImageSrc,
  };
};

export default useAllImages;
