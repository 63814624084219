import React from "react"
import SocialFooter from "../socialFooter/SocialFooter"
import FooterContainer from "./Footer.style"

const Footer = () => {
  return (
    <FooterContainer>
      <SocialFooter />
    </FooterContainer>
  )
}

export default Footer
