import styled from "styled-components"

const StyledNavigation = styled.nav`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: space-between;
`

export default StyledNavigation
