import * as React from "react"

function SvgSocialTwitterDark({ width = "50", height = "50", className = "", color = "#222221" }) {
  return (
    <svg width={width} height={height} className={className} viewBox="0 0 40 40" aria-label="title">
      <title>Twitter</title>
      <path d="M35.7 12v1c0 10.7-8.1 23-23 23-4.6 0-8.8-1.3-12.4-3.6.6.1 1.3.1 1.9.1 3.8 0 7.3-1.3 10-3.5-3.5-.1-6.5-2.4-7.5-5.6.5.1 1 .1 1.5.1.7 0 1.5-.1 2.1-.3-3.7-.7-6.5-4-6.5-7.9v-.1c1.1.6 2.3 1 3.7 1C3.4 14.8 2 12.3 2 9.5c0-1.5.4-2.9 1.1-4.1 4 4.9 9.9 8.1 16.7 8.4-.1-.6-.2-1.2-.2-1.8 0-4.5 3.6-8.1 8.1-8.1 2.3 0 4.4 1 5.9 2.6 1.8-.4 3.6-1 5.1-2-.7 2-2 3.6-3.7 4.6 1.6-.2 3.2-.6 4.6-1.3-1 1.6-2.4 3-3.9 4.2z" fill={color} />
    </svg>
  )
}

export default SvgSocialTwitterDark
