import { isIOS } from "./platform"

class ModalModeClass {
  static query

  static elements

  static pageYOffset = 0

  static isModalActive = false

  static initialize = () => {
    ModalModeClass.query = document.getElementsByTagName("html")
    ModalModeClass.elements = Array.from(ModalModeClass.query)
  }

  static active = () => {
    if (!ModalModeClass.query) this.initialize()
    if (isIOS()) {
      if (window.pageYOffset !== 0) ModalModeClass.pageYOffset = window.pageYOffset
      ModalModeClass.elements[0].classList.add("u-overflow-hidden-ios")
    } else {
      ModalModeClass.elements[0].classList.add("u-overflow-hidden")
    }
    ModalModeClass.elements[0].classList.add("no-smooth-scroll")
  }

  static deactive = () => {
    if (!ModalModeClass.query) this.initialize()
    ModalModeClass.elements[0].classList.remove("u-overflow-hidden-ios")
    ModalModeClass.elements[0].classList.remove("u-overflow-hidden")
    if (isIOS()) {
      setTimeout(() => {
        window.scrollTo(0, ModalModeClass.pageYOffset)
      }, 0)
    }
    setTimeout(() => {
      ModalModeClass.elements[0].classList.remove("no-smooth-scroll")
    }, 10)
    ModalModeClass.isModalActive = false
  }
}

export default ModalModeClass
