/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

require('./src/styles/globals.scss');

const React = require('react');
const { gsap } = require('gsap');
const { ScrollTrigger } = require('gsap/ScrollTrigger');
const SEO = require('./src/components/seo/SEO').default;
const LayoutMain = require('./src/components/layout/LayoutMain').default;

exports.wrapPageElement = ({ element, props }) => {
  gsap.registerPlugin(ScrollTrigger);

  return (
    <>
      <SEO {...props} />
      <LayoutMain>{element}</LayoutMain>
    </>
  );
};

exports.onInitialClientRender = () => {
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 0);
};
