import { useState, useEffect } from "react"
import ModalModeClass from "../helpers/modalMode"

const useLayoutData = () => {
  const [activeCategory, setActiveCategory] = useState(undefined)
  const [isHome, setIsHome] = useState(undefined)
  const [searchQuery, setSearchQuery] = useState(null)
  const [formOption, setFormOption] = useState(undefined)
  const [isActiveModal, setIsActiveModal] = useState(false)

  useEffect(() => {
    if (isActiveModal) {
      ModalModeClass.active()
    } else {
      ModalModeClass.deactive()
    }
  }, [isActiveModal])

  return {
    active: activeCategory,
    setActiveCategory,
    isHome,
    setIsHome,
    searchQuery,
    setSearchQuery,
    formOption,
    setFormOption,
    isActiveModal,
    setIsActiveModal,
  }
}

export default useLayoutData
