import React from "react"

import SvgSocialFacebookDark from "../svgs/SvgSocialFacebookDark"
import SvgSocialTwitterDark from "../svgs/SvgSocialTwitterDark"
import SvgSocialInstagramDark from "../svgs/SvgSocialInstagramDark"

import { ButtonSocialRollAnchorStyle, ButtonSocialRollStyle } from "./ButtonSocialRoll.style"

const ButtonSocialRoll = ({ className = "", type, typeRoll, mouseOver, url }) => {
  const getType = (theType, normalRoll) => {
    switch (theType) {
      case "facebook":
        return <SvgSocialFacebookDark color="#ffffff" className={`o-buttonsocial__svg o-buttonsocial__svg${normalRoll}`} />
      case "facebook_dark":
        return <SvgSocialFacebookDark className={`o-buttonsocial__svg o-buttonsocial__svg${normalRoll}`} />
      case "facebook_yellow":
        return <SvgSocialFacebookDark color="#ffcd54" className={`o-buttonsocial__svg o-buttonsocial__svg${normalRoll}`} />
      case "twitter":
        return <SvgSocialTwitterDark color="#ffffff" className={`o-buttonsocial__svg o-buttonsocial__svgtwitter o-buttonsocial__svg${normalRoll}`} />
      case "twitter_dark":
        return <SvgSocialTwitterDark className={`o-buttonsocial__svg o-buttonsocial__svgtwitter o-buttonsocial__svg${normalRoll}`} />
      case "twitter_yellow":
        return <SvgSocialTwitterDark color="#ffcd54" className={`o-buttonsocial__svg o-buttonsocial__svgtwitter o-buttonsocial__svg${normalRoll}`} />
      case "instagram":
        return <SvgSocialInstagramDark color="#ffffff" className={`o-buttonsocial__svg o-buttonsocial__svg${normalRoll}`} />
      case "instagram_dark":
        return <SvgSocialInstagramDark className={`o-buttonsocial__svg o-buttonsocial__svg${normalRoll}`} />
      case "instagram_yellow":
        return <SvgSocialInstagramDark color="#ffcd54" className={`o-buttonsocial__svg o-buttonsocial__svg${normalRoll}`} />
      default:
        return null
    }
  }
  if (url) {
    return (
      <ButtonSocialRollAnchorStyle className={className} mouseOver={mouseOver} href={url} target="_blank" rel="noopener noreferrer">
        <div className="o-buttonsocial__svgbg o-buttonsocial__svgbgnormal" />
        <div className="o-buttonsocial__svgbg o-buttonsocial__svgbgroll" />
        {getType(type, "normal")}
        {getType(typeRoll, "roll")}
      </ButtonSocialRollAnchorStyle>
    )
  }
  return (
    <ButtonSocialRollStyle className={className} mouseOver={mouseOver}>
      <div className="o-buttonsocial__svgbg o-buttonsocial__svgbgnormal" />
      <div className="o-buttonsocial__svgbg o-buttonsocial__svgbgroll" />
      {getType(type, "normal")}
      {getType(typeRoll, "roll")}
    </ButtonSocialRollStyle>
  )
}

export default ButtonSocialRoll
