import React, { useState } from "react"
import { StyledNavBar, ItemsWrapper } from "./NavBar.style"
import NavItem from "../../../elements/header/navItem/NavItem"
import Navigation from "../../../elements/header/navigation/Navigation"
import SearchSvgAnimated from "../../../elements/search/searchSvgAnimated"
import SvgHamburguer from "../../../elements/svgs/SvgHamburger"
import SvgJeepstories from "../../../elements/svgs/SvgJeepstories"
import SvgCancel from "../../../elements/svgs/SvgCancel"

const NavBar = ({ className, searchClick, hamburguerClick, activeHamburger, closeHamburger, solid, handleNavigation, activeSearch, closeSearch, isHome, formOption }) => {
  const moduleClass = "m-navbar"
  const [showOver, setShowOver] = useState(false)

  const handlePlay = () => {
    setShowOver(true)
  }
  const handleStop = () => {
    setShowOver(false)
  }

  return (
    <StyledNavBar className={className} activeHamburger={activeHamburger} activeSearch={activeSearch} solid={solid} isHome={isHome} formOption={formOption}>
      <Navigation>
        <ItemsWrapper className={`${moduleClass}__hamburguer-mb`}>
          <NavItem onClick={hamburguerClick} className={`${moduleClass}__hamburguer tag-menu-hamburguesa`} dkHidden>
            <SvgHamburguer />
          </NavItem>
          <NavItem onClick={closeHamburger} className={`${moduleClass}__hamburguer-close tag-menu-cerrar`}>
            <SvgCancel />
          </NavItem>
        </ItemsWrapper>
        <ItemsWrapper className={`${moduleClass}__logo-mb`}>
          <NavItem onClick={() => handleNavigation("/")} className={`${moduleClass}__jeep-logo tag-menu-home`}>
            <SvgJeepstories />
          </NavItem>
        </ItemsWrapper>
        <ItemsWrapper className={`${moduleClass}__categories`} activeSearch={activeSearch} solid={solid}>
          {/* <NavItem url={'/'}>todas</NavItem>
          <NavItem url={'/historias/destacadas'}>destacadas</NavItem>
          <NavItem url={'/historias/off-road'}>off-road</NavItem>
          <NavItem url={'/historias/familia'}>familia</NavItem>
          <NavItem url={'/historias/aventura'}>aventura</NavItem> */}
          <NavItem id={1} className={`${moduleClass}__categories-item tag-menu-todas`} onClick={() => handleNavigation("/")}>
            todas
          </NavItem>
          <NavItem id={2} className={`${moduleClass}__categories-item tag-menu-destacadas`} onClick={() => handleNavigation("/destacadas/")}>
            destacadas
          </NavItem>
          <NavItem id={3} className={`${moduleClass}__categories-item tag-menu-offroad`} onClick={() => handleNavigation("/off-road/")}>
            off-road
          </NavItem>
          <NavItem id={4} className={`${moduleClass}__categories-item tag-menu-familia`} onClick={() => handleNavigation("/familia/")}>
            familia
          </NavItem>
          <NavItem id={5} className={`${moduleClass}__categories-item tag-menu-aventura`} onClick={() => handleNavigation("/aventura/")}>
            aventura
          </NavItem>
        </ItemsWrapper>
        <ItemsWrapper>
          <NavItem>
            <NavItem onClick={searchClick} onMouseEnter={handlePlay} onMouseLeave={handleStop} className={`${moduleClass}__search tag-menu-busqueda`}>
              <SearchSvgAnimated showOver={showOver} />
            </NavItem>
            <NavItem onClick={closeSearch} className={`${moduleClass}__search-close tag-menu-cerrar-busqueda`}>
              <SvgCancel />
            </NavItem>
          </NavItem>
        </ItemsWrapper>
      </Navigation>
    </StyledNavBar>
  )
}

// Expected prop values
NavBar.propTypes = {}

NavBar.defaultProps = {}

export default NavBar
