import React, { useEffect } from "react"
import { ThemeProvider } from "styled-components"

import LayoutContext from "./layout.context"
import ContextAllImages from "../../context/allImages.context"
import useLayoutData from "../../hooks/useLayoutData"
import useAllImages from "../../hooks/useAllImages"
import GlobalStyles from "../../theme/globalStyles"
import themeDefault from "../../theme/themeDefault"
import Header from "../modules/header/Header"
import Footer from "../modules/footer/Footer"

const Layout = ({ children }) => {
  const layoutData = useLayoutData()
  const initialImages = useAllImages()

  useEffect(() => {
    return () => {
      window.scrollTo(0, 0)
    }
  }, [])

  if (!initialImages.allImages) return null

  return (
    <div>
      <LayoutContext.Provider value={layoutData}>
        <ContextAllImages.Provider value={initialImages}>
          <ThemeProvider theme={themeDefault}>
            <GlobalStyles />
            <Header />
            <main>{children}</main>
            <Footer />
          </ThemeProvider>
        </ContextAllImages.Provider>
      </LayoutContext.Provider>
    </div>
  )
}
export default Layout
