import * as React from 'react';

function SvgCancel(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" {...props}>
      <path
        fill="currentColor"
        d="M28.82 25.54l-7.94-7.56 7.86-7.7-2.8-2.86-7.96 7.8-8.05-7.66-2.75 2.9 7.94 7.56-7.86 7.7 2.8 2.86 7.96-7.8 8.05 7.66z"
      />
    </svg>
  );
}

export default SvgCancel;
