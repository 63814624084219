import * as React from "react"

function SvgSocialFacebookDark({ width = "50", height = "50", className = "", color = "#222221" }) {
  return (
    <svg width={width} height={height} className={className} viewBox="0 0 40 40" aria-label="title">
      <title>Facebook</title>
      <path d="M16.9 35.8v-16h-4.4v-5.1h4.4v-3.8c0-4.4 2.7-6.8 6.6-6.8 1.9 0 3.5.1 4 .2V9h-2.7c-2.1 0-2.5 1-2.5 2.5v3.3h5.1l-.7 5.1h-4.4V35s-1.4.4-2.6.6c-1.6.2-2.8.2-2.8.2z" fill={color} />
    </svg>
  )
}

export default SvgSocialFacebookDark
