import styled, { css } from "styled-components"
import { device } from "../../../../theme/mediaQueries"

const StyledNavItem = styled.div`
  display: block;
  cursor: pointer;
  svg {
    pointer-events: none;
  }

  ${props =>
    props.xsHidden &&
    css`
      display: none;
    `}

  @media ${device.md} {
    display: block;

    ${props =>
      props.dkHidden &&
      css`
        display: none;
      `}
  }
`

export default StyledNavItem
