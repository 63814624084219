import * as React from 'react';

function SvgHamburguer(props) {
  return (
    <svg fill="currentColor" viewBox="0 0 35 17" aria-label="title" {...props}>
      <title>Menú</title>
      <path d="M0 0h35v3H0zM0 14h35v3H0zM0 7h35v3H0z" />
    </svg>
  );
}

export default SvgHamburguer;
