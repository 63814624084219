import styled from "styled-components"
import { Link } from "gatsby"
import { device } from "../../../theme/mediaQueries"

export const SocialFooterContainer = styled.div`
  width: 100%;
  @media ${device.md} {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 60px;
    height: 100%;
  }
  @media ${device.lg} {
    padding: 0 136px;
  }
  @media ${device.xl} {
    padding: 0 240px;
  }
`
export const SocialList = styled.ul`
  display: flex;
  justify-content: space-between;
  max-width: 208px;
  margin: 0 auto;

  .o-buttonsocial__svgbg,
  svg {
    pointer-events: none;
  }
  .o-buttonsocial__svgbgnormal {
    background-color: ${props => props.theme.vars.titanWhite};
  }
  .o-buttonsocial__svgbgroll {
    background-color: ${props => props.theme.vars.goldenTainoi};
  }

  @media ${device.sm} {
    max-width: 178px;
    margin-bottom: 31px;
    .social-footer__svg {
      height: 25px;
      width: 25px;
    }
    .social-footer__svgtwitter {
      height: 20px;
      width: 20px;
    }
  }

  @media ${device.md} {
    margin: 0;
    padding: 0;
    width: 178px;
    max-width: none;
  }

  @media ${device.xl} {
    width: 221px;
    .social-footer__svg {
      height: 26px;
      width: 26px;
    }
    .social-footer__svgtwitter {
      height: 20px;
      width: 20px;
    }
  }
`

export const LinksList = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  justify-content: space-between;
  height: 60px;

  @media ${device.sm} {
    flex-direction: row;
    height: auto;
    width: 200px;
    margin: 0 auto;

    .social-footer__separator::after {
      content: "|";
      padding-left: 24px;
    }
  }

  @media ${device.md} {
    margin: 0;
    padding: 0;
  }

  @media ${device.xl} {
    width: 245px;
    .social-footer__separator::after {
      content: "|";
      padding-left: 45px;
    }
  }
`

export const ItemLink = styled(Link)`
  text-transform: uppercase;
  color: ${props => props.theme.vars.titanWhite};
  font-size: 11px;
  :hover {
    @media ${device.md} {
      color: ${props => props.theme.vars.goldenTainoi};
      :after {
        color: ${props => props.theme.vars.titanWhite};
      }
    }
  }
  @media ${device.sm} {
    font-size: 10px;
  }
`
