import React, { useState, useEffect, useContext } from "react"
import { string } from "prop-types"
import { navigate } from "gatsby"
import { StyledHeader, CategoriesContainer, SearchCotainer } from "./Header.style"
import NavBar from "./navBar/NavBar"
import useScrollListener from "../../../hooks/useScrollListener"
import ModalModeClass from "../../../helpers/modalMode"
import { isIOS } from "../../../helpers/platform"
import NavItem from "../../elements/header/navItem/NavItem"
import InputSearch from "../../elements/inputSearch/InputSearch"
import LayoutContext from "../../layout/layout.context"

let actualScroll = 0
function Header({ className }) {
  const moduleClass = "m-header"
  const [scrollToSolid, setScrollToSolid] = useState(0)
  const [scrollToHide, setScrollToHide] = useState(0)
  const [solid, setSolid] = useState(false)
  const [hide, setHide] = useState(false)
  const [activeSearch, setActiveSearch] = useState(false)
  const [activeHamburger, setActiveHamburger] = useState(false)
  const layoutContext = useContext(LayoutContext)

  const openSearch = () => {
    setActiveSearch(true)
  }

  const closeSearch = () => {
    if (isIOS()) {
      setTimeout(setActiveSearch(false), 100)
    } else {
      setActiveSearch(false)
    }
  }

  const openHamburger = () => {
    setActiveHamburger(true)
  }

  const closeHamburger = () => {
    if (isIOS()) {
      setTimeout(setActiveHamburger(false), 100)
    } else {
      setActiveHamburger(false)
    }
  }

  const handleNavigation = url => {
    closeHamburger()
    closeSearch()
    navigate(url)
  }

  const updateSolidState = () => {
    const winWidth = window.innerWidth
    if (layoutContext.formOption) {
      setScrollToSolid(20)
    } else if (winWidth <= 768) {
      setScrollToSolid(80)
    } else if (winWidth > 768 && winWidth <= 1024) {
      setScrollToSolid(100)
    } else if (winWidth > 1024 && winWidth <= 1366) {
      setScrollToSolid(120)
    } else if (winWidth > 1366) {
      setScrollToSolid(400)
    }
  }

  const updateHideState = () => {
    const winWidth = window.innerWidth
    if (winWidth <= 768) {
      setScrollToHide(40)
    } else if (winWidth > 768 && winWidth <= 1024) {
      setScrollToHide(50)
    } else if (winWidth > 1024 && winWidth <= 1366) {
      setScrollToHide(60)
    } else if (winWidth > 1366) {
      setScrollToHide(200)
    }
  }

  const onSCroll = currentScroll => {
    if (scrollToSolid === 0 || scrollToHide === 0) return
    if (currentScroll >= scrollToSolid) {
      setSolid(true)
    } else {
      setSolid(false)
    }
    if (currentScroll >= scrollToHide && solid && !activeSearch && !activeHamburger) {
      if (currentScroll < actualScroll) {
        setHide(false)
      } else {
        setHide(true)
      }
    } else {
      setHide(false)
    }
    actualScroll = currentScroll
  }

  useScrollListener("window", onSCroll)

  useEffect(() => {
    if (activeHamburger) {
      ModalModeClass.active()
    } else {
      ModalModeClass.deactive()
    }
  }, [activeHamburger])

  useEffect(() => {
    updateSolidState()
    updateHideState()
  }, [])

  useEffect(() => {
    updateSolidState()
    updateHideState()
  }, [layoutContext.formOption])

  useEffect(() => {
    if (activeHamburger && activeSearch) {
      setActiveSearch(false)
    }
  }, [activeHamburger])

  useEffect(() => {
    if (activeSearch && activeHamburger) {
      setActiveHamburger(false)
    }
  }, [activeSearch])

  return (
    <StyledHeader className={className} solid={solid} hide={hide} activeHamburger={activeHamburger} activeSearch={activeSearch} formOption={layoutContext.formOption}>
      <NavBar className={`${moduleClass}__navbar`} hamburguerClick={openHamburger} closeHamburger={closeHamburger} closeSearch={closeSearch} searchClick={openSearch} activeHamburger={activeHamburger} solid={solid} handleNavigation={handleNavigation} activeSearch={activeSearch} isHome={layoutContext.isHome} formOption={layoutContext.formOption} />
      <CategoriesContainer className={`${moduleClass}__categories`} activeHamburger={activeHamburger}>
        <NavItem id={1} className={`${moduleClass}__categories-item tag-menu-todas`} onClick={() => handleNavigation("/")}>
          todas
        </NavItem>
        <NavItem id={2} className={`${moduleClass}__categories-item tag-menu-destacadas`} onClick={() => handleNavigation("/destacadas/")}>
          destacadas
        </NavItem>
        <NavItem id={3} className={`${moduleClass}__categories-item tag-menu-offroad`} onClick={() => handleNavigation("/off-road/")}>
          off-road
        </NavItem>
        <NavItem id={4} className={`${moduleClass}__categories-item tag-menu-familia`} onClick={() => handleNavigation("/familia/")}>
          familia
        </NavItem>
        <NavItem id={5} className={`${moduleClass}__categories-item tag-menu-aventura`} onClick={() => handleNavigation("/aventura/")}>
          aventura
        </NavItem>
      </CategoriesContainer>
      <SearchCotainer className={`${moduleClass}__search-conteiner tag-menu-busqueda`} activeSearch={activeSearch}>
        <InputSearch closeSearch={closeSearch} />
      </SearchCotainer>
    </StyledHeader>
  )
}

Header.propTypes = {
  className: string,
}

Header.defaultProps = {
  className: "",
}

export default React.memo(Header)
