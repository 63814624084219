/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https: //www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import { shape, string } from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql, withPrefix } from 'gatsby';

function SEO(props) {
  const { location, lang } = props;
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          titleTemplate
          description
          image
          siteUrl
        }
      }
    }
  `);

  const canonical = `${site.siteMetadata.siteUrl}${
    location ? location.pathname : ''
  }`;
  const params = {
    title: site.siteMetadata.title,
    description: site.siteMetadata.description,
    image: site.siteMetadata.image,
    url: canonical,
  };

  // if (location.pathname.includes('historias')) {
  //   params.title = ''
  //   params.description = ''
  //   params.image = ''
  // }

  const initialMetas = [
    {
      name: 'viewport',
      content:
        'width=device-width, height=device-height, initial-scale=1, minimum-scale=1, maximum-scale=1, user-scalable=no',
    },
    { name: 'apple-mobile-web-app-capable', content: 'yes' },
    { name: 'mobile-web-app-capable', content: 'yes' },
    { name: 'title', content: params.title },
    { name: 'description', content: params.description },
    { name: 'image', content: params.image },
    { name: 'type', content: 'website' },
    { name: 'application-name', content: site.siteMetadata.title },
    { property: 'og:type', content: 'website' },
    { property: 'og:site_name', content: site.siteMetadata.title },
    { property: 'og:url', content: canonical },
    { property: 'og:title', content: params.title },
    { property: 'og:description', content: params.description },
    { property: 'og:image', content: params.image },
    { property: 'twitter:card', content: 'summary_large_image' },
    { property: 'twitter:url', content: canonical },
    { property: 'twitter:title', content: params.title },
    { property: 'twitter:description', content: params.description },
    { property: 'twitter:image', content: params.image },
  ];

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={params.title}
      defaultTitle={site.siteMetadata.title}
      titleTemplate={site.siteMetadata.titleTemplate}
      meta={initialMetas}
    >
      <link rel="canonical" href={canonical} />
      <link
        rel="apple-touch-icon"
        sizes="57x57"
        href={`${process.env.GATSBY_SITE_URL}${withPrefix(
          '/'
        )}assets/metas/apple-touch-icon-57x57.png`}
      />
      <link
        rel="apple-touch-icon"
        sizes="60x60"
        href={`${process.env.GATSBY_SITE_URL}${withPrefix(
          '/'
        )}assets/metas/apple-touch-icon-60x60.png`}
      />
      <link
        rel="apple-touch-icon"
        sizes="72x72"
        href={`${process.env.GATSBY_SITE_URL}${withPrefix(
          '/'
        )}assets/metas/apple-touch-icon-72x72.png`}
      />
      <link
        rel="apple-touch-icon"
        sizes="76x76"
        href={`${process.env.GATSBY_SITE_URL}${withPrefix(
          '/'
        )}assets/metas/apple-touch-icon-76x76.png`}
      />
      <link
        rel="apple-touch-icon"
        sizes="114x114"
        href={`${process.env.GATSBY_SITE_URL}${withPrefix(
          '/'
        )}assets/metas/apple-touch-icon-114x114.png`}
      />
      <link
        rel="apple-touch-icon"
        sizes="120x120"
        href={`${process.env.GATSBY_SITE_URL}${withPrefix(
          '/'
        )}assets/metas/apple-touch-icon-120x120.png`}
      />
      <link
        rel="apple-touch-icon"
        sizes="152x152"
        href={`${process.env.GATSBY_SITE_URL}${withPrefix(
          '/'
        )}assets/metas/apple-touch-icon-152x152.png`}
      />
      <link
        rel="apple-touch-icon"
        sizes="167x167"
        href={`${process.env.GATSBY_SITE_URL}${withPrefix(
          '/'
        )}assets/metas/apple-touch-icon-167x167.png`}
      />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href={`${process.env.GATSBY_SITE_URL}${withPrefix(
          '/'
        )}assets/metas/apple-touch-icon-180x180.png`}
      />
      <link
        rel="shortcut icon"
        href={`${process.env.GATSBY_SITE_URL}${withPrefix(
          '/'
        )}assets/metas/favicon.png`}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href={`${process.env.GATSBY_SITE_URL}${withPrefix(
          '/'
        )}assets/metas/favicon-16x16.png`}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href={`${process.env.GATSBY_SITE_URL}${withPrefix(
          '/'
        )}assets/metas/favicon-32x32.png`}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="48x48"
        href={`${process.env.GATSBY_SITE_URL}${withPrefix(
          '/'
        )}assets/metas/favicon-48x48.png`}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="72x72"
        href={`${process.env.GATSBY_SITE_URL}${withPrefix(
          '/'
        )}assets/metas/favicon-72x72.png`}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="96x96"
        href={`${process.env.GATSBY_SITE_URL}${withPrefix(
          '/'
        )}assets/metas/favicon-96x96.png`}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="144x144"
        href={`${process.env.GATSBY_SITE_URL}${withPrefix(
          '/'
        )}assets/metas/favicon-144x144.png`}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="192x192"
        href={`${process.env.GATSBY_SITE_URL}${withPrefix(
          '/'
        )}assets/metas/favicon-192x192.png`}
      />
    </Helmet>
  );
}

SEO.defaultProps = {
  location: {},
  lang: `es`,
};

SEO.propTypes = {
  location: shape({
    pathname: string,
  }),
  lang: string,
};

export default SEO;
