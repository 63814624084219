import styled, { css } from "styled-components"
import { device } from "../../../theme/mediaQueries"
import bgImage from "../../../images/bg-noise.png"

export const StyledHeader = styled.header`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  height: 74px;
  font-size: 0px;
  padding: 0 10px;
  position: fixed;
  top: 0;
  transition: opacity 0.2s ease, transform 200ms 300ms ease;
  width: 100%;
  z-index: 15;

  &:before {
    background-image: url(${bgImage});
    background-position: center;
    background-repeat: repeat;
    content: " ";
    display: block;
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
  }

  ${props =>
    props.formOption &&
    css`
      border-bottom: solid 1px rgba(0, 0, 0, 0.2);
    `}

  ${props =>
    props.solid &&
    css`
      box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.2);
      &:before {
        opacity: 1;
      }
      .m-header__navbar {
        color: ${props.theme.vars.black};
      }
      .c-search-animation__animation {
        svg path {
          fill: ${props.theme.vars.black} !important;
        }
      }
    `}

  ${props =>
    props.hide &&
    css`
      transform: translateY(-74px);
    `}

  ${props =>
    props.activeHamburger &&
    css`
      height: 100vh;
      display: block;
      padding-top: 25px;
      &:before {
        opacity: 1;
      }
      .m-header__navbar {
        color: ${props.theme.vars.black};
        margin-bottom: 20%;
      }
    `}

  ${props =>
    props.activeSearch &&
    css`
      height: 139px;
      display: block;
      padding-top: 25px;
      &:before {
        opacity: 1;
      }
      .m-header__navbar {
        color: ${props.theme.vars.black};
      }
    `}

  @media ${device.sm} {
    padding-left: 60px;
    padding-right: 60px;
    height: 100px;

    ${props =>
      props.hide &&
      css`
        transform: translateY(-100px);
      `}

    ${props =>
      props.activeHamburger &&
      css`
        height: 100vh;
        padding-top: 38px;
        .m-header__navbar {
          margin-bottom: 22%;
        }
      `}

    ${props =>
      props.activeSearch &&
      css`
        height: 174px;
        padding-top: 38px;
      `}
  }

  @media ${device.md} {
    ${props =>
      props.activeSearch &&
      css`
        height: 182px;
      `}
  }

  @media ${device.lg} {
    padding-left: 134px;
    padding-right: 134px;

    ${props =>
      props.activeSearch &&
      css`
        padding-top: 41px;
        height: 184px;
      `}
  }

  @media ${device.xl} {
    padding-left: 240px;
    padding-right: 240px;

    ${props =>
      props.activeSearch &&
      css`
        padding-top: 38px;
        height: 218px;
      `}
  }
`

export const CategoriesContainer = styled.div`
  display: none;
  align-items: center;
  height: 354px;
  justify-content: space-between;
  flex-direction: column;
  font-family: ${props => props.theme.vars.avenirNext};
  font-weight: ${props => props.theme.vars.fontBold};
  font-size: 18px;
  text-transform: uppercase;

  ${props =>
    props.activeHamburger &&
    css`
      display: flex;
    `}

  @media ${device.sm} {
    font-size: 30px;
    height: 621px;
  }
`

export const SearchCotainer = styled.div`
  display: none;
  align-items: center;
  justify-content: center;
  margin-top: 13px;

  ${props =>
    props.activeSearch &&
    css`
      display: block;
    `}

  @media ${device.sm} {
    margin-top: 18px;
  }

  @media ${device.md} {
    padding: 0 154px;
    margin-top: 23px;
  }

  @media ${device.lg} {
    margin-top: 24px;
    padding: 0 190px;
  }

  @media ${device.xl} {
    padding: 0 358px;
    margin-top: 40px;
  }
`
