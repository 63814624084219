import React, { useState, useContext } from "react"
import { navigate } from "gatsby"

import LayoutContext from "../../layout/layout.context"
import SearchSvgAnimated from "../search/searchSvgAnimated"
import StyledInputContainer from "./InputSearch.style"

const InputSearch = ({ closeSearch }) => {
  const elementClass = "e-input-search"
  const [search, setSearch] = useState("")
  const [showOver, setShowOver] = useState(false)
  const { setSearchQuery } = useContext(LayoutContext)

  const onChange = e => {
    setSearch(e.target.value)
  }

  const onSearch = () => {
    if (!search) {
      return
    }
    const str = search.replace(/\s\s+/g, " ").trim()
    setSearchQuery(str)
    navigate(`/busqueda/?q=${str}`)
    setTimeout(() => closeSearch(), 200)
  }

  const handleKeyDown = e => {
    if (e.key === "Enter") {
      onSearch()
    }
  }
  const handlePlay = () => {
    setShowOver(true)
  }
  const handleStop = () => {
    setShowOver(false)
  }

  return (
    <StyledInputContainer>
      <input onChange={onChange} onKeyDown={handleKeyDown} placeholder="Buscar historia..." />
      <div role="button" tabIndex="0" className={`${elementClass}__svg-search tag-menu-busqueda`} onClick={onSearch} onMouseEnter={handlePlay} onMouseLeave={handleStop} onKeyPress={null}>
        <SearchSvgAnimated showOver={showOver} />
      </div>
    </StyledInputContainer>
  )
}

export default InputSearch
