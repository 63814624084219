import styled, { css } from "styled-components"
import { device } from "../../../theme/mediaQueries"

export const ButtonSocialRollAnchorStyle = styled.a`
  height: 37px;
  width: 37px;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
  &:hover {
    @media ${device.md} {
      .o-buttonsocial__svgbgroll {
        opacity: 1;
      }
      .o-buttonsocial__svgnormal {
        transform: translate(-50%, 200%);
      }
      .o-buttonsocial__svgroll {
        transform: translate(-50%, -50%);
      }
    }
  }

  .o-buttonsocial__svgbg {
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 50%;
    z-index: 1;
  }

  .o-buttonsocial__svgbgroll {
    opacity: 0;
    z-index: 3;
    transition: all 0.5s ease;
  }
  .o-buttonsocial__svgbgnormal {
    z-index: 2;
    transition: all 0.5s ease;
  }
  .o-buttonsocial__svg {
    height: 27px;
    width: 27px;

    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 4;
    transition: all 0.5s ease;
  }
  .o-buttonsocial__svgtwitter {
    height: 20px;
    width: 20px;
  }
  .o-buttonsocial__svgroll {
    transform: translate(-50%, -200%);
  }

  @media ${device.sm} {
    .o-buttonsocial__svg {
      height: 25px;
      width: 25px;
    }
    .o-buttonsocial__svgtwitter {
      height: 20px;
      width: 20px;
    }
  }

  @media ${device.xl} {
    .o-buttonsocial__svg {
      height: 26px;
      width: 26px;
    }
    .o-buttonsocial__svgtwitter {
      height: 20px;
      width: 20px;
    }
  }
  ${props =>
    props.mouseOver &&
    css`
      @media ${device.md} {
        .o-buttonsocial__svgbgroll {
          opacity: 1;
        }
        .o-buttonsocial__svgnormal {
          transform: translate(-50%, 200%);
        }
        .o-buttonsocial__svgroll {
          transform: translate(-50%, -50%);
        }
      }
    `}
`

export const ButtonSocialRollStyle = styled(ButtonSocialRollAnchorStyle).attrs({ as: "div" })``
