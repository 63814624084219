import { useState, useEffect } from "react"

const useScrollListener = (element, callback) => {
  const [currentScroll, setcurrentScroll] = useState(0)

  useEffect(() => {
    const onElementScroll = () => {
      const scroll = window.scrollY || document.body.scrollTop || document.documentElement.scrollTop
      setcurrentScroll(scroll)
    }

    if (element.current) {
      element.current.removeEventListener("scroll", onElementScroll)
      element.current.addEventListener("scroll", onElementScroll, {
        passive: true,
      })
      callback(currentScroll)
      return () => element.current.removeEventListener("scroll", onElementScroll)
    }
    if (document) {
      let currentElement = null
      switch (element) {
        case "window":
          window.addEventListener("scroll", onElementScroll, {
            passive: true,
          })
          callback(currentScroll)
          currentElement = window
          break
        case "document":
          document.addEventListener("scroll", onElementScroll, {
            passive: true,
          })
          callback(currentScroll)
          currentElement = document
          break
        default:
          // eslint-disable-next-line no-case-declarations
          const item = document.getElementsByTagName(element)[0] || document.getElementsByClassName(element)[0] || document.getElementById(element)
          item.addEventListener("scroll", onElementScroll, { passive: true })
          callback(currentScroll)
          currentElement = item
          break
      }
      if (currentElement !== null) return () => currentElement.removeEventListener("scroll", onElementScroll)
    }
    return () => {}
  }, [currentScroll])

  return { currentScroll }
}

export default useScrollListener
